import * as React from 'react';
import { navigate } from 'gatsby'
import api from '../util/api';
import {
  Button, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography,
  CircularProgress, IconButton, InputAdornment
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Layout from '../components/Layout';
import Background from '/images/411440x810.jpeg'
import Logo from '/images/logo/iseelogo1.png'
import './index.css';


export default function SignIn() {
  const [user_validate_err, setUservalidate] = React.useState(false)
  const [pass_validate_err, setPassvalidate] = React.useState(false)
  const [user_error_text, setUserError] = React.useState('')
  const [pass_error_text, setPassError] = React.useState('')
  const [remember, setRemember] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const [onload, setOnLoad] = React.useState(false)
  const [user, setUser] = React.useState('')
  const [pass, setPass] = React.useState('')
  const [translate, setTranslate] = React.useState(null);
  const [lang, setLang] = React.useState(null);

  const handleSubmit = () => {
    let login_validate = onValidate(user, pass)
    if (login_validate) {
      setOnLoad(true)
      onSignin(user, pass, lang)
    }
  };

  const handleLineLogin = () => {
    api.redirectLineLogin()
  }

  const onValidate = (user, pass) => {
    return true
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSignin = async (user, pass, lang) => {
    try {
      let res = await api.auth({
        user: user,
        pass: pass,
      }, remember)
      let userdata = await api.getUserInfo()
      if (userdata.role_active === "partner_province") {
        navigate(`/province`, { replace: true })
      } else {
        navigate("/", { replace: true })
      }
    } catch (err) {
      console.log("err", err)
      let _err = err
      if (lang === 'EN') {
        // Too many sign-in attempts from this user, please try again after 
        if (_err === 'กรุณาระบุ User และ Password') _err = 'Please enter User and Password.'
        else if (_err === 'User หรือ Password ไม่ถูกต้อง') _err = 'User or Password is incorrect.'
        else if (_err === 'มีการพยายามเข้าสู่ระบบมากเกินไปจากผู้ใช้นี้ โปรดลองอีกครั้งหลังจาก 2 นาที') _err = 'Too many sign-in attempts from this user, Please try again after 2 minutes'
      }
      setOnLoad(false)
      setUserError(_err)
      setUservalidate(true)
      setPassvalidate(true)
    }
  }

  return (
    <Layout
      getLang={(translate, lang) => {
        setTranslate(translate)
        setLang(lang)
      }}
    >

      <div className='bg-cover bg-no-repeat bg-center' style={{ backgroundImage: `url(${Background})` }}>
        <div className='min-h-[100vh] flex justify-end  xl:px-32 xl:py-[100px]'>
          <div className="w-full xl:w-1/3 rounded-3xl shadow-2xl backdrop-opacity-10 backdrop-invert bg-white/90">
            <Box
              // className='p-32 xl:p-16 md:4'
              className='flex flex-col items-center py-32 px-8 xl:py-4 xl:px-16'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <div className='h-[20px]'></div>
              <img src={Logo} alt="" style={{ width: 200 }} />
              <Typography component="h1" variant="h5">
                {translate?.login}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  style={{ backgroundColor: '#e9e9e9' }}
                  error={user_validate_err}
                  onChange={(e) => setUser(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  helperText={user_error_text}
                  autoFocus
                />
                <TextField
                  style={{ backgroundColor: '#e9e9e9' }}
                  error={pass_validate_err}
                  onChange={(e) => setPass(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  helperText={pass_error_text}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" checked={remember} onChange={() => setRemember(!remember)} />}
                  label="จดจำฉัน"
                /> */}
                <Button
                  disabled={onload}
                  onClick={handleSubmit}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                  size="large"
                  startIcon={onload ? <CircularProgress size={16} color="inherit" /> : false}
                >
                  {onload ? translate?.logging : translate?.login}
                </Button>
                <Typography className='text-center'>
                  {translate?.or}
                </Typography>
                <Link className='text-base' href="/register" variant="body2">
                  <Button
                    disabled={onload}
                    fullWidth
                    variant="contained"
                    sx={{ bgcolor: '#03b900', mt: 1, mb: 2 }}
                    size="large"
                    startIcon={onload ? <CircularProgress size={16} color="inherit" /> : false}
                  >
                    {translate?.register_for_use}
                  </Button>
                </Link>
                {/* <Button
                  disabled={onload}
                  onClick={handleLineLogin}
                  fullWidth
                  variant="contained"
                  sx={{ bgcolor: '#03b900', mt: 1, mb: 2 }}
                  size="large"
                  startIcon={onload ? <CircularProgress size={16} color="inherit" /> : false}
                >
                  {onload ? translate?.logging : translate?.login_LINE}
                </Button> */}
                <Grid container>
                  <Grid item xs>
                    <Link className='text-base' href="/forgetpass" variant="body2">
                      {translate?.forget_pass}
                    </Link>
                  </Grid>
                  {/* <Grid item>
                    <Link className='text-base' href="/register" variant="body2">
                      {translate?.register_for_use}
                    </Link>
                  </Grid> */}
                </Grid>
              </Box>
            </Box>

          </div>
        </div>
      </div>
    </Layout >
  );
}